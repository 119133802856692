import { useEffect, useRef, useState } from "react";


const useDynamicSVGImport = (name: string) => {

	const ImportedIconRef = useRef();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>();

	useEffect(() => {
		setLoading(true);
		const importIcon = async () => {
			try {

				ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+ref!../assets/${name}.svg`)).default;

			} catch (err: any) {

				setError(err);
			} finally {
				setLoading(false);
			}
		};
		importIcon();
	}, [name]);

	return { error, loading, SvgIcon: ImportedIconRef.current };
}

export default useDynamicSVGImport;