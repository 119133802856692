import React from 'react';
import StepTitle from '../step-title';
import {StepProps} from "../../../types";
import { useTheme } from '../../../theme';

interface RatingStarProps {
   fill?: boolean;
}

function RatingStar({ fill = false }: RatingStarProps) {
   const { theme } = useTheme();

   return (
      fill ? (
         <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1062 2.01023C20.4753 1.27497 21.5247 1.27497 21.8938 2.01023L26.7662 11.7187C27.2027 12.5885 28.0341 13.1925 28.9962 13.3389L39.7352 14.9728C40.5485 15.0965 40.8728 16.0947 40.2876 16.6728L32.5599 24.3069C31.8676 24.9908 31.55 25.9682 31.7081 26.9284L33.4728 37.6467C33.6064 38.4585 32.7573 39.0753 32.0266 38.6974L22.3782 33.707C21.5138 33.2599 20.4862 33.2599 19.6218 33.707L9.97337 38.6974C9.24266 39.0753 8.3936 38.4585 8.52724 37.6467L10.2919 26.9284C10.45 25.9682 10.1324 24.9908 9.44007 24.3069L1.71241 16.6728C1.12717 16.0947 1.45148 15.0965 2.26478 14.9728L13.0038 13.3389C13.9659 13.1925 14.7973 12.5885 15.2338 11.7187L20.1062 2.01023Z" fill="url(#paint0_linear_407_122433)" stroke="url(#paint1_linear_407_122433)" strokeWidth="2" />
            <defs>
               <linearGradient id="paint0_linear_407_122433" x1="21" y1="-2" x2="21" y2="46" gradientUnits="userSpaceOnUse">
                  <stop stopColor={`${theme.colors.border}`} />
                  <stop offset="1" stopColor={`${theme.colors.background}`} />
               </linearGradient>
               <linearGradient id="paint1_linear_407_122433" x1="21" y1="-2" x2="21" y2="46" gradientUnits="userSpaceOnUse">
                  <stop stopColor={`${theme.colors.border}`} />
                  <stop offset="1" stopColor={`${theme.colors.background}`} />
               </linearGradient>
            </defs>
         </svg>
      ) : (
         <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1062 2.01023C20.4753 1.27497 21.5247 1.27497 21.8938 2.01023L26.7662 11.7187C27.2027 12.5885 28.0341 13.1925 28.9962 13.3389L39.7352 14.9728C40.5485 15.0965 40.8728 16.0947 40.2876 16.6728L32.5599 24.3069C31.8676 24.9908 31.55 25.9682 31.7081 26.9284L33.4728 37.6467C33.6064 38.4585 32.7573 39.0753 32.0266 38.6974L22.3782 33.707C21.5138 33.2599 20.4862 33.2599 19.6218 33.707L9.97337 38.6974C9.24266 39.0753 8.3936 38.4585 8.52724 37.6467L10.2919 26.9284C10.45 25.9682 10.1324 24.9908 9.44007 24.3069L1.71241 16.6728C1.12717 16.0947 1.45148 15.0965 2.26478 14.9728L13.0038 13.3389C13.9659 13.1925 14.7973 12.5885 15.2338 11.7187L20.1062 2.01023Z" stroke="url(#paint0_linear_407_122434)" strokeWidth="2" />
            <defs>
               <linearGradient id="paint0_linear_407_122434" x1="21" y1="-2" x2="21" y2="46" gradientUnits="userSpaceOnUse">
                  <stop stopColor={`${theme.colors.border}`} />
                  <stop offset="1" stopColor={`${theme.colors.background}`} />
               </linearGradient>
            </defs>
         </svg>
      )
   )
}


interface RatingStepsProps extends StepProps {
   rating: number;
}

function RatingStep({ step, rating, onAnswer }: RatingStepsProps) {
   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StepTitle title={step.title} />
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
               {
                  Array(step.properties.count).fill(null).map((_, index) => (
                     <div onClick={() => onAnswer(index + 1)} key={index}>
                        <RatingStar fill={rating >= index + 1} />
                     </div>
                  ))
               }
            </div>
         </div>
      </div>
   )
}

export default RatingStep;