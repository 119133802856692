import React from 'react';
import { useTheme } from '../../theme';

interface TitleProps {
   title: string;
}

function StepTitle({ title }: TitleProps) {
   const { theme } = useTheme();
   return (
      <div className="step-title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontStyle: 'normal', textAlign: 'center', color: `${theme.colors.title}` }}>{title}</div>
   )
}

export default StepTitle;