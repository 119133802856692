import { redirectHotFixPatch } from "./redirect-hot-patch";
import { getCurrentRevision } from "./revision";

function defineBaseUrl() {
   // Parse the URL of the current window location
   const url = new URL(window.location.href);

   // Try to retrieve the targetUrl from the URL parameters
   let targetUrl = url.searchParams.get('targetUrl');

   if (targetUrl) {
      return targetUrl;
   } else if (process.env.REACT_APP_API_URL) {
      return process.env.REACT_APP_API_URL;
   }
}

function hasPreviewMode() {
   return !!new URL(window.location.href).searchParams.get('preview');
}

const url = defineBaseUrl();

export function createResponseFor(step: any, answer: any) {
   const { id: stepId, responseCategory, type, label } = step;

   return {
      stepId,
      type,
      label,
      responseCategory,
      value: answer,
      revision: getCurrentRevision(),
      submittedAt: new Date().toISOString()
   }
}

export async function getJourney() {
   const path = window.location.pathname;

   const paths = path.split('/').filter(Boolean);  // filter(Boolean) is used to remove empty strings from the array

   // TODO remove the redirect hot fix patch when the campaign demos are over.
   const [campaignId, variant] = redirectHotFixPatch(paths);


   // TODO Extract this validation to a generic one.
   const variantIndex = Number(variant);

   if (isNaN(variantIndex)) {
      throw new Error("Second path segment cannot be converted to a number.");
   }

   return fetch(`${url}/campaign/${campaignId}/variant/${variantIndex}`, {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      }
   })
      .then(response => response.json());
}

export async function createJourneyResponseBucketFor(campaignId: string, variantId: string) {
   if (hasPreviewMode()) {
      return Promise.resolve(1);
   }

   return fetch(`${url}/response`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         campaignId,
         variantId
      })
   })
      .then(response => response.json())
      .then(({ responseId }) => responseId);
}

// TODO: identify when should submit
export async function updateResponseBucketFor(responseId: string, response: any) {
   if (!responseId) {
      console.error('There is no responseId', responseId);
      return Promise.reject();
   }

   if (hasPreviewMode()) {
      return Promise.resolve();
   }

   return fetch(`${url}/response/${responseId}`, {
      method: 'PUT',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(response)
   });
}
