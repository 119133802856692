import React from 'react';
import EmailStep from "./steps/email";
import LongTextStep from "./steps/longtext";
import OptionsStep from "./steps/multi-options/options";
import RatingStep from "./steps/rating";
import ReferralStep from "./steps/referral";
import RedirectStep from './steps/redirect';
import {Option, StepSlideProps} from "../../types";



export function StepSlide({ index, children }: StepSlideProps) {
   return (
      <div className='slide-item' data-index={index}>
         {children}
      </div>
   )
}



export function BuildStepFactory(step: any, answered: any, onAnswer: (step: any, answer: any) => void, isViewed: boolean) {
   switch (step.type) {
      case 'multipleOptions': return <OptionsStep step={step} answered={answered} onAnswer={(option: Option) => onAnswer(step, option)}  />
      case 'longText': return <LongTextStep step={step} onAnswer={(text: string) => onAnswer(step, text)}  />
      case 'referral': return <ReferralStep step={step} onAnswer={(shared: boolean) => onAnswer(step, shared)}  />
      case 'rating': return <RatingStep step={step} rating={answered[step.id].value} onAnswer={(rating: number) => onAnswer(step, rating)} />
      case 'email': return <EmailStep step={step} onAnswer={(email: string) => onAnswer(step, email)}  />
      case 'redirect': return <RedirectStep step={step} onAnswer={(redirected: boolean) => onAnswer(step, redirected)} isViewed={isViewed}  />
      default: return null;
   }
}
