import React, { useRef, useImperativeHandle, useState } from 'react';
import StepTitle from '../step-title';
import Text from '../../utils/text';
import Button from '../../utils/button';
import {StepProps} from "../../../types";


interface EmailInputProps {
   onEnter?: (email: string) => void;
}

function EmailInput({ onEnter }: EmailInputProps, ref: any) {
   const emailInputRef = useRef<HTMLInputElement>(null);
   const [hasError, setHasError] = useState(false);

   useImperativeHandle(ref, () => ({
      getEmail: () => emailInputRef.current && emailInputRef.current.value,
      isValid: () => {
         const email = emailInputRef.current && emailInputRef.current.value || '';

         if (!validateEmail(email)) {
            setHasError(true);
            emailInputRef.current?.focus();
            return false;
         }

         setHasError(false);
         return true;

         function validateEmail(email: string) {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return emailRegex.test(email);
         }
      }
   }));

   return (
      <>
         <input style={{
            border: '1px solid #BCBCBC',
            background: '#FFFFFF',
            borderRadius: '4px',
            width: '100%',
            height: 60,
            padding: 18
         }} placeholder='Enter your email...' type="email" ref={emailInputRef}
            onKeyDown={(event) => {
               const { value } = event.target as HTMLInputElement;
               if (event.key === 'Enter' && typeof onEnter === 'function') {
                  if (!ref.current.isValid()) return;
                  
                  return onEnter(value);
               }
            }} />
         <div style={{ visibility: hasError ? 'visible' : 'hidden', height: 40, paddingTop: 8 }}>
            <Text text="Incorrect email address, please try again." variant='error' />
         </div>
      </>
   )
}

export interface EmailInputHandler {
   getEmail: () => string;
   isValid: () => boolean;
}

const EmailInputRef = React.forwardRef<EmailInputHandler, EmailInputProps>(EmailInput);

function EmailStep({ step, onAnswer }: StepProps) {
   const emailInputHandler = useRef<EmailInputHandler>(null);

   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StepTitle title={step.title} />
            <div style={{ marginBottom: 12 }}>
               <Text text={step.properties.description} />
            </div>
            <EmailInputRef ref={emailInputHandler} onEnter={(email) => onAnswer(email)} />
            <Button text={step.properties.buttonText} onClick={(event) => {
               event.preventDefault();

               if (emailInputHandler.current && emailInputHandler.current.isValid()) {
                  onAnswer(emailInputHandler.current.getEmail());
               }
            }} />
         </div>
      </ div>
   )
}

export default EmailStep;