import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import SelectionOption from './selection-option';
import Text from '../../../utils/text';
import GridItem from "./grid-item";
import { GridItemProps, OptionsProps } from "../../../../types";


export const gridItemStyle: CSSProperties = {
   flex: '0 0 50%',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   maxWidth: '100%',
   borderRadius: '8px',
   boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)'
}

function GridItemOther({ isSelected, onSelect }: Pick<GridItemProps, 'isSelected' | 'onSelect'>) {
   const inputRef = useRef<HTMLInputElement>(null);
   const [showInput, setShowInput] = useState(false);

   useEffect(() => {
      if (showInput) {
         inputRef.current?.focus();
      }
   }, [showInput]);

   return (
      <SelectionOption isSelected={isSelected} style={gridItemStyle} onClick={() => setShowInput(true)}>
         <div style={{ textAlign: 'center', padding: 8, overflowWrap: 'break-word', wordWrap: 'break-word', maxHeight: 90, overflow: 'auto' }}>
            {<Text text="Other" strong />}
            <input ref={inputRef} enterKeyHint="next" style={{ display: showInput ? 'block' : 'none', width: '100%', outline: 'none', border: 'none', borderBottom: '0.5px solid #282421' }} type="text"
               onBlur={() => {
                  setShowInput(false);
               }}
               onKeyDown={(event) => {
                  const { value } = event.target as HTMLInputElement;
                  if (event.key === 'Enter') {
                     setShowInput(false);
                     onSelect({ id: 'other', value })
                  }
               }} />
         </div>
      </SelectionOption>
   );
}

function GridLayout({ options, selectedOption, allowOtherOption, onSelect }: Pick<OptionsProps, 'options' | 'onSelect' | 'selectedOption' | 'allowOtherOption'>) {
   if (!options) return null;
   if (options.length === 0) return null;

   const rows = [];
   let lastRowItems = 0; // count items in the last row
   for (let i = 0; i < options.length; i += 2) {
      const currentOption = options[i];
      const nextOption = options[i + 1];
      const isCurrentOptionSelected = currentOption && currentOption.id === selectedOption?.id;
      const isNextOptionSelected = nextOption && nextOption.id === selectedOption?.id;
      const lineMarginBottom = nextOption ? 20 : 0;
      
      rows.push(
         <div className="grid-row" key={i} style={{ display: 'flex', flex: 1, justifyContent: 'center', maxHeight: 140, marginBottom: lineMarginBottom }}>
            <GridItem isSelected={isCurrentOptionSelected} option={currentOption} onSelect={onSelect}  />
            {nextOption && <GridItem isSelected={isNextOptionSelected} option={nextOption} onSelect={onSelect}  />}
         </div>
      );
      
      // count how many items are in the last row
      lastRowItems = nextOption ? 2 : 1;
   }

   // Add the "Other" option
   if (allowOtherOption) {
      // if last row has only 1 item, add "Other" option to the last row
      if (lastRowItems === 1) {
         let lastRow = rows[rows.length - 1];
         rows[rows.length - 1] = (
            <div className="grid-row" key="last" style={{ display: 'flex', flex: 1, justifyContent: 'center', maxHeight: 140, marginBottom: 20 }}>
              {lastRow.props.children}
              <GridItemOther isSelected={selectedOption?.id === 'other'} onSelect={onSelect} />
            </div>
         );
      } else {
         // else add a new row for the "Other" option
         rows.push(
            <div className="grid-row" key="other" style={{ display: 'flex', flex: 1, justifyContent: 'center', maxHeight: 140, marginBottom: 20 }}>
               <GridItemOther isSelected={selectedOption?.id === 'other'} onSelect={onSelect} />
            </div>
         );
      }
   }

   return <>{rows}</>;
}
export default GridLayout;