// TODO remove it when the patch campaigns are over.
export function redirectHotFixPatch([campaign, variant]: string[]) {
   if (campaign !== 'demo') {
      return [campaign, variant];
   }

   switch (variant) {
      case 'l':
         return ['6489ca4edd736c72b4a146c1', '0'];
      case 'f':
         return ['6489ca4edd736c72b4a146c1', '1'];
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
         return ['6489cf5ddd736c72b4a146c7', '0'];
      case 's':
      case 'w':
      case 'u':
      case 'o':
      case 'a':
         return ['6489ce88dd736c72b4a146c6', '0'];
      case 'n':
      case 'd':
      case 'i':
      case 'v':
         return ['6489cf5ddd736c72b4a146c7', '0'];
      case 'x':
         return ['64b9296ab322f2328bc162a2', '0'];
      default:
         return [campaign, variant];
   }
}