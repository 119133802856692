import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from '../../../../theme';
import {Option} from "../../../../types";


interface SelectionOptionProps {
   isSelected: boolean;
   option?: Option;
   onSelect?: (option: Option) => void;
   onClick?: () => void;
   children: ReactNode;
   style?: CSSProperties;
}

export default function SelectionOption({ option, isSelected, onSelect, onClick, style = {}, children }: SelectionOptionProps) {
   const { theme } = useTheme();

   const selectionStyle = isSelected ? { border: `1px solid ${theme.colors.selection.border}`, background: theme.colors.selection.background } : {};

   return (
      <div style={{ ...style, ...selectionStyle }} onClick={() => {
         if (option && onSelect) {
            onSelect({ id: option.id, value: option.value });
         } else {
            onClick && onClick();
         }
      }}>
         {children}
      </div>
   )
}
