import React from "react";
import SelectionOption from "./selection-option";
import Icon from "../../../Icon";
import Text from "../../../utils/text";
import {gridItemStyle} from "./grid-layout";
import {GridItemProps} from "../../../../types";

const GridItem = ({ isSelected, option, onSelect }: GridItemProps) => {
    return (
        <SelectionOption data-testid={`grid-item-${option.id}`} option={option} isSelected={isSelected} style={gridItemStyle} onSelect={(option) => onSelect(option)}>
            {option.asset && option.asset.type === 'image' && (
                <div style={{ flex: 1, background: `url(${new URL(option.asset.value, 'https://x.wonka.cloud')}) no-repeat center center fixed`, backgroundSize: 'cover', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />
            )}

            {option.asset && option.asset.type === 'svg' && (
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8 }}>
                    <Icon name={option.asset.value} />
                </div>
            )}

            <div style={{ textAlign: 'center', padding: 8, overflowWrap: 'break-word', wordBreak: 'break-word', maxHeight: 90, overflow: 'auto' }}>
                <Text data-testid={`text-${option.id}`} text={option.value} strong />
            </div>
        </SelectionOption>
    );
}

export default GridItem