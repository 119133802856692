import React, { ReactNode, createContext, useCallback, useContext, useState } from "react";

interface ThemeColors {
   answer: string;
   button: string;
   buttonContent: string;
   title: string;
   border: string;
   background: string;
   selection: {
      border: string;
      background: string;
   };
}

interface Theme {
   background: string;
   logo: string;
   font: string;
   fontSize: string;
   colors: ThemeColors;
}

interface ThemeContextType {
   theme: Theme;
   updateTheme: (theme: Partial<Theme>) => void;
}


const defaultTheme = {
   background: '/images/wonka_background_theme.png',
   logo: '/images/wonka_logo_theme.png',
   font: 'Regular',
   fontSize: 'small',
   colors: {
      answer: '',
      button: 'linear-gradient(to right, #8973F6, #D565F7)',
      buttonContent: '#FFFFFF',
      title: '#282421',
      border: '#8973F6',
      background: '#D565F7',
      selection: {
         border: '#5831A9',
         background: 'rgba(88, 49, 169, 0.1)'
      }
   }
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
   const [theme, setTheme] = useState<Theme>(defaultTheme);

   const updateTheme = useCallback((newTheme: Partial<Theme>) => {
      setTheme((prevTheme) => ({ ...prevTheme, ...newTheme }));
   }, []);

   return (
      <ThemeContext.Provider value={{ theme, updateTheme }}>
         {children}
      </ThemeContext.Provider>
   )
};

export const useTheme = (): ThemeContextType => {
   const context = useContext(ThemeContext);
   if (!context) {
      throw new Error('useTheme must be used within a ThemeProvider');
   }
   return context;
};
