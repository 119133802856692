import React from 'react';
import StepTitle from '../../step-title';
import ListLayout from './list-layout';
import GridLayout from './grid-layout';
import { OptionsProps, StepProps } from "../../../../types";

function OptionsLayout({ options, selectedOption, layout = 'list', allowOtherOption = false, onSelect }: OptionsProps) {
   let optionLayout = null;
   switch (layout) {
      case 'list': {
         optionLayout = <ListLayout options={options} selectedOption={selectedOption} onSelect={onSelect} />
         break;
      }
      case 'grid': {
         optionLayout = <GridLayout options={options} selectedOption={selectedOption} onSelect={onSelect} allowOtherOption={allowOtherOption} />
         break;
      }
   }

   if (layout === 'grid') {
      return (
         <div style={{ display: 'flex', flexDirection: 'column', flex: 1, flexBasis: 0, overflow: 'hidden', padding: '6px 14px' }}>
            <GridLayout options={options} selectedOption={selectedOption} onSelect={onSelect} allowOtherOption={allowOtherOption} />
         </div>
      );
   } else {
      return (
         <div style={{ display: 'flex', flexDirection: 'column', flex: 1, flexBasis: 0, overflow: 'hidden', padding: '5px 4px' }}>
            <ListLayout options={options} selectedOption={selectedOption} onSelect={onSelect} />
         </div>
      )
   }
}

function OptionsStep({ step, answered, onAnswer }: StepProps) {
   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
         <StepTitle title={step.title} />
         <OptionsLayout options={step.properties.options} layout={step.properties.layout} allowOtherOption={step.properties.allowOtherOption} selectedOption={answered[step.id].value} onSelect={(option) => onAnswer(option)} />
      </div>
   )
}

export default OptionsStep;