import React, { useState, useRef, useCallback } from 'react';
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import GiftProgress from '../steps/gift/gift-progress';
import { BuildStepFactory, StepSlide } from '../steps/builder';
import { createResponseFor, updateResponseBucketFor } from '../../lib/response';
import useViewedService from '../../hooks/use-viewed-service';
import {JourneyCarouselProps, Step} from "../../types";

const settings = {
   className: 'main-slider',
   centerMode: true,
   infinite: false,
   mobileFirst: true,
   swipeToSlide: true,
   centerPadding: "20px",
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   speed: 600,
} as Settings;

function fixSwipeBugWhenInputOrTextareaOnFocus() {
   // Select all input and textarea elements
   const inputs: (HTMLInputElement | HTMLTextAreaElement)[] = Array.from(document.querySelectorAll('input, textarea') as NodeListOf<HTMLInputElement | HTMLTextAreaElement>);

   // Get the currently focused element
   const focusedElement: Element | null = document.activeElement;

   // Iterate through the elements find the focused element and call the blur() method on it.
   const input = inputs.filter((input: HTMLInputElement | HTMLTextAreaElement) => input === focusedElement)[0];

   input?.blur();
}

function initialAnswered(steps: any) {
   return steps.reduce((emptyAnswered: any, step: any) => {
      emptyAnswered[step.id] = {};
      return emptyAnswered;
   }, {});
}

function initialGiftRef(steps: any) {
   return steps.map(() => React.createRef());
}

function JourneyCarousel({ steps, responseId }: JourneyCarouselProps) {

   const sliderRef = useRef<Slider>(null);
   const giftStepHandlers = useRef(initialGiftRef(steps));
   const [answered, setAnswer] = useState(initialAnswered(steps));
   const { isViewed, markAndActOnFirstView } = useViewedService({ 0: true });
   const [shouldSlide, setShouldSlide] = useState(true);

   const onAnswer = useCallback((step: any, answer: any) => {
      console.log('Answer for step:', step, answer);
      setAnswer({ ...answered, [step.id]: { value: answer } });
      updateResponseBucketFor(responseId, createResponseFor(step, answer));
      sliderRef.current && sliderRef.current.slickNext();
   }, [answered, responseId]);


   return (
      <>
         <Slider {...settings} swipe={shouldSlide} touchMove={shouldSlide} ref={sliderRef} onInit={() => {
         }} beforeChange={(_: never, newIndex: number) => {
            // Hack fix an issue that the carousel freak out when a user is on focus on input or textarea and try to swipe.
            fixSwipeBugWhenInputOrTextareaOnFocus();

            markAndActOnFirstView(newIndex, () => {
               giftStepHandlers?.current[newIndex]?.current?.play();
            });
         }} afterChange={(current) => {
            // Check if the current step index is the last journey step, if it is disable the slide function.
            if (current === steps.length - 1) {
               setShouldSlide(false);
            }
         }} >
            {
               steps.map((step: Step, index: number) => (
                  <StepSlide key={step.id} index={index + 1} >
                     <div className="card" style={{
                        flex: 1,
                        flexBasis: 0,
                        overflow: 'hidden',
                     }}>
                        {BuildStepFactory(step, answered, onAnswer, isViewed(index))}
                     </div>
                     <GiftProgress completed={index + 1} total={steps.length} ref={giftStepHandlers.current[index]} />
                  </StepSlide>
               ))
            }
         </Slider >
      </>
   );
}

export default JourneyCarousel;