import React, { CSSProperties, useEffect, useState } from 'react';
import { useTheme } from '../theme';
import ReactDOM from 'react-dom';

const backgroundStyle: CSSProperties = {
   position: 'fixed',
   height: '100%',
   width: '100%',
   top: 0,
   right: 0,
   bottom: 0,
   left: 0,
   zIndex: -1,
   backgroundSize: 'cover',
   backgroundPosition: 'center',
   backgroundColor: '#F8F8F8'
}

export function BrandBackground() {
   const [backgroundDOMElement, setBackgroundDOMElement] = useState<HTMLElement | null>(null);
   const { theme } = useTheme();
   const { background } = theme;

   useEffect(() => {
      setBackgroundDOMElement(document.getElementById('background'));
   }, []);

   if (!backgroundDOMElement) {
      return null;
   }

   return (
      ReactDOM.createPortal(<div style={{ ...backgroundStyle, backgroundImage: `url(${new URL(background, 'https://x.wonka.cloud')})` }} />, backgroundDOMElement)
   )
}

export function BrandLogo() {
   const { theme } = useTheme();
   const { logo } = theme;

   if (!logo) {
      return <div style={{height: 8}}></div>
   }

   return (
      <div className="brand-section" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8 }}>
         <img style={{ height: '100%', maxHeight: 60 }} src={`${new URL(logo, 'https://x.wonka.cloud')}`} />
      </div>
   )
}