import useDynamicSVGImport from "../hooks/useDynamicSVGImport";
import React from "react";
import {IconType} from "../types";

interface SvgIconProps {
	[key: string]: any;
}
const Icon = ({ name, ...rest }: IconType) => {
	const {loading, SvgIcon } = useDynamicSVGImport(name);


	if (loading) {
		return <div />;
	}
	if (SvgIcon) {
		const DynamicSvgIcon = SvgIcon as React.ComponentType<SvgIconProps>;
		return <DynamicSvgIcon {...rest} />;
	}

	return null;
}

export default Icon;
