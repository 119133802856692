import React from 'react';
import { useTheme } from '../../theme';

interface ButtonProps {
   text: string;
   onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function Button({ text, onClick }: ButtonProps) {
   const { theme } = useTheme();

   return <button style={{
      background: theme.colors.button,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
      borderRadius: '8px',
      padding: '12px 40px',
      color: theme.colors.buttonContent,
      border: '1px solid transparent',
   }} onClick={onClick}>
      <div style={{
         fontFamily: 'Roboto',
         fontWeight: 600,
         fontSize: 16
      }}>{text}</div>
   </button>
}


export default Button;