import React, { useEffect } from 'react';
import StepTitle from '../step-title';
import Text from '../../utils/text';
import {StepProps} from "../../../types";

const defaultRedirectAfterThreeSeconds = 3000;

function RedirectStep({ step, onAnswer, isViewed }: StepProps) {
   useEffect(() => {
      if (isViewed) {
         setTimeout(() => {
            onAnswer(true);
            window.location.href = step.properties.redirectUrl;
         }, defaultRedirectAfterThreeSeconds);
      }
   }, [isViewed, onAnswer, step.properties.redirectUrl]);

   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StepTitle title={step.title} />
            <Text text={step.properties.description} strong align='center' />
         </div>
      </div>
   )
}

export default RedirectStep;