import { useCallback, useState } from "react";

type ViewedContainer = Record<number, boolean>;
type Callback = () => void;

export default function useViewedService(initialViewedSteps: ViewedContainer) {
   const [viewed, setViewed] = useState<ViewedContainer>(initialViewedSteps);

   const markAsViewed = useCallback((stepIndex: number) => {
      setViewed(prevViewed => ({
         ...prevViewed,
         [stepIndex]: true
      }));
   }, []);

   const markAndActOnFirstView = useCallback((stepIndex: number, callback?: Callback) => {
      if (!viewed[stepIndex]) {
         markAsViewed(stepIndex);
         if (callback) {
            callback();
         }
      }
   }, [viewed, markAsViewed]);

   const isViewed = useCallback((stepIndex: number): boolean => {
      return Boolean(viewed[stepIndex]);
   }, [viewed]);

   return { isViewed, markAndActOnFirstView };
};
