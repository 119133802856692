import React from 'react';
import Text from '../../utils/text';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import GiftGrowAnimation from "./giftbc.json";
import GiftOpenAnimation from "./giftopen.json";


interface GiftProgressBarProps {
   completed: number;
   total: number
}

function Gift({ completed, total }: GiftProgressBarProps, ref: any) {
   if (completed > total) {
      completed = total;
   }

   const completedWidth = Math.round((completed / total) * 100);

   return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingBottom: 8 }}>
         <div style={{ marginRight: 8 }}>
            <div style={{ width: completedWidth === 100 ? 130 : completedWidth, minWidth: 30 }}>
               <Lottie lottieRef={ref} animationData={completedWidth === 100 ? GiftOpenAnimation : GiftGrowAnimation} loop={false} autoplay={false} />
            </div>
         </div>
         <div style={{ display: 'flex', marginBottom: 4 }}>
            <div style={{ marginRight: 4 }}>
               <Text text={`${roundToNearestTen(completedWidth)}%`} strong />
            </div>
            <Text text="Completed!" />
         </div>
      </div>
   )
}

function roundToNearestTen(number: number) {
   return Math.round(number / 10) * 10;
}

const GiftRef = React.forwardRef<LottieRefCurrentProps, GiftProgressBarProps>(Gift);

export default GiftRef


// TODO DO NOT DELETE.
// function GiftProgressBar({ completed, total }: GiftProgressBarProps) {
//    if (completed > total) {
//       completed = total;
//    }

//    const completedWidth = (completed / total) * 100;
//    const notCompletedWidth = 100 - completedWidth;

//    return (
//       <div>
//          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 16 }}>
//             <div style={{ marginRight: 8 }}>
//                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M7.65625 1.23047C8.09375 0.464844 8.88672 0 9.76172 0H9.84375C11.1562 0 12.25 1.09375 12.25 2.40625C12.25 3.74609 11.1562 4.8125 9.84375 4.8125H7.875V7H6.125V4.8125H4.15625C2.81641 4.8125 1.75 3.74609 1.75 2.40625C1.75 1.09375 2.81641 0 4.15625 0H4.21094C5.08594 0 5.87891 0.464844 6.31641 1.23047L7 2.35156L7.65625 1.23047ZM5.19531 1.88672C4.97656 1.53125 4.62109 1.3125 4.21094 1.3125H4.15625C3.52734 1.3125 3.0625 1.80469 3.0625 2.40625C3.0625 3.03516 3.52734 3.5 4.15625 3.5H6.15234L5.19531 1.88672ZM7.82031 3.5H9.84375C10.4453 3.5 10.9375 3.03516 10.9375 2.40625C10.9375 1.80469 10.4453 1.3125 9.84375 1.3125H9.76172C9.35156 1.3125 8.99609 1.53125 8.77734 1.88672L7.82031 3.5ZM6.125 14V7.875H7.875V14H6.125Z" fill="#FFA285" />
//                   <path d="M4.15625 4.8125H6.125V7H0.875C0.382812 7 0 6.61719 0 6.125V4.375C0 3.91016 0.382812 3.5 0.875 3.5H1.99609C2.40625 4.29297 3.19922 4.8125 4.15625 4.8125ZM13.125 7H7.875V4.8125H9.84375C10.7734 4.8125 11.5664 4.29297 11.9766 3.5H13.125C13.5898 3.5 14 3.91016 14 4.375V6.125C14 6.61719 13.5898 7 13.125 7ZM0.875 7.875H6.125V14H2.1875C1.44922 14 0.875 13.4258 0.875 12.6875V7.875ZM7.875 14V7.875H13.125V12.6875C13.125 13.4258 12.5234 14 11.8125 14H7.875Z" fill="#FF52CF" />
//                </svg>
//             </div>
//             <div style={{ marginRight: 4 }}>
//                <Text text={`${Math.trunc(completedWidth)}%`} />
//             </div>
//             <Text text="Completed!" weight={400} />
//          </div>
//          <div style={{ display: 'flex', height: 20, width: '100%' }}>
//             <div style={{ height: 'inherent', width: `${completedWidth}%`, backgroundColor: `${theme.colors.progressBarCompleted}`, borderBottomLeftRadius: '10px', borderBottomRightRadius: completedWidth === 100 ? '10px' : 0 }}></div>
//             <div style={{ height: 'inherent', width: `${notCompletedWidth}%`, backgroundColor: `${theme.colors.progressBarNotCompleted}`, borderBottomRightRadius: '10px' }}></div>
//          </div>
//       </div>
//    )
// }