import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import JourneyCarousel from './components/carousel';
import { BrandLogo, BrandBackground } from './components/brand';
import PoweredBy from './components/poweredby';
import { createJourneyResponseBucketFor, getJourney } from './lib/response';
import { useTheme } from './theme';
import { CSSTransition } from 'react-transition-group';


import './App.css';

function useAppLoader() {
   const appLoaderRef = useRef<HTMLElement | null>(null);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      if (!appLoaderRef.current) {
         appLoaderRef.current = document.getElementById('loader');
      }
   }, []);

   const hideLoading = useCallback(() => {
      if (appLoaderRef.current) {
         appLoaderRef.current.classList.add('loader--done');
         setTimeout(() => {
            setIsLoading(false);
         }, 800);
      }
   }, []);

   return useMemo(() => ({
      isLoading,
      hideLoading
   }), [hideLoading, isLoading]);
}

// Calculate the height without the address bar
// Set the height and width of the element
function updateHeight<T extends HTMLElement>(node: T) {
   node.style.height = `${window.innerHeight}px`;
   node.style.width = `${window.innerWidth}px`;
}

export default function App() {
   const { updateTheme } = useTheme();
   const appLayoutRef = useRef<HTMLDivElement>(null);
   const { isLoading, hideLoading } = useAppLoader();
   const [journey, setJourney] = useState<any>(null);
   const [responseId, setResponseId] = useState<string | null>(null);

   console.log(journey);

   useEffect(() => {
      function onResize() {
         appLayoutRef.current && updateHeight(appLayoutRef.current);
      }

      // Listen for window resize events
      window.addEventListener("resize", onResize, false);

      // Initial height calculation
      onResize();

      // Cleanup function
      return () => {
         window.removeEventListener("resize", onResize, false);
      };
   }, []);

   useEffect(() => {
      async function startJourney() {
         try {
            const journey = await getJourney();

            if (journey) {
               console.log('starting journey.', journey);

               const responseId: string = await createJourneyResponseBucketFor(journey.campaignId, journey.id);

               console.log('for response id', responseId);

               updateTheme(journey.theme);
               setResponseId(responseId);
               setJourney(journey);

               hideLoading();
            }
         } catch (error) {
            console.error(error);
         }
      }

      startJourney();
   }, [hideLoading]);

   return (
      <CSSTransition<HTMLDivElement> nodeRef={appLayoutRef} in={!isLoading} timeout={500} classNames="fade" mountOnEnter unmountOnExit onEnter={() => {
         appLayoutRef.current && updateHeight(appLayoutRef.current);
      }}>
         <div ref={appLayoutRef}>
            <BrandBackground />
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
               <BrandLogo />
               <div style={{ flex: 1, overflow: 'hidden' }}>
                  {/* <JourneyCarousel steps={window.renderData.variant.steps} responseId={responseId} /> */}
                  {
                     journey && <JourneyCarousel steps={journey.steps} responseId={responseId} />
                  }
               </div>
               <PoweredBy />
            </div>
         </div>
      </CSSTransition>
   );
}