import React, { useImperativeHandle, useRef } from 'react';
import Button from '../../utils/button';
import StepTitle from '../step-title';
import {StepProps} from "../../../types";



interface LongTextInputProps {
}

function LongTextInput({ }: LongTextInputProps, ref: any) {
   const textareaInputRef = useRef<HTMLTextAreaElement>(null);

   useImperativeHandle(ref, () => ({
      getText: () => {
         if (!textareaInputRef.current) return '';

         return sanitizeTextareaInput(textareaInputRef.current.value);

         function sanitizeTextareaInput(input: string | null) {
            if (!input) return '';

            const sanitizedInput = input
               .replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&#039;');
            return sanitizedInput.trim();
         }
      },
   }));

   return (
      <>
         <textarea style={{
            width: '100%',
            minHeight: 100,
            border: '1px solid #BCBCBC',
            borderRadius: '4px',
            padding: 18,
            resize: 'none'
         }} placeholder='Share your review' ref={textareaInputRef} />
      </>
   )
}

interface LongTextInputHandler {
   getText: () => string;
}

const LongTextInputRef = React.forwardRef<LongTextInputHandler, LongTextInputProps>(LongTextInput);

function LongTextStep({ step, onAnswer }: StepProps) {
   const longTextInputHandler = useRef<LongTextInputHandler>(null);

   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
         <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <StepTitle title={step.title} />
            <div style={{ width: '100%', marginBottom: '30px' }}>
               <LongTextInputRef ref={longTextInputHandler} />
            </div>
            <Button text="Send" onClick={() => {
               onAnswer(longTextInputHandler.current?.getText());
            }} />
         </div>
      </div>
   )
}

export default LongTextStep;