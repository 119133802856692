import React from 'react';
import SelectionOption from './selection-option';
import Text from '../../../utils/text';
import { OptionsProps } from "../../../../types";
import Icon from "../../../Icon";

function spaceBetweenListItem(count: number) {
   if (count === 8) return 10;
   if (count === 6) return 15;

   return 20;
}

function ListLayout({ options, selectedOption, onSelect }: Pick<OptionsProps, 'options' | 'selectedOption' | 'onSelect'>) {
   return (
      <>
         {options.map((option, index) => (
            <SelectionOption option={option} isSelected={option.id === selectedOption?.id} key={option.id} onSelect={(option) => onSelect(option)} style={{
               display: 'flex',
               alignItems: 'center',
               padding: '8px 12px',
               width: '100%',
               borderRadius: 8,
               boxShadow: "0px 0px 8px #D6D6D6",
               minHeight: 0,
               maxHeight: 60,
               flex: 1,
               marginBottom: index !== options.length - 1 ? spaceBetweenListItem(options.length) : 0,
            }}>
               {option.asset && option.asset.type === 'image' && (
                  <div style={{ marginRight: 12, height: '100%' }}><img style={{ height: '100%' }} src={`${option.asset.value}`} /></div>
               )}

               {option.asset && option.asset.type === 'svg' && (
                  <div style={{ marginRight: 12, height: '100%' }}>
                     <Icon name={option.asset.value} />
                  </div>
               )}
               <Text text={option.value} strong />
            </SelectionOption>
         ))
         }
      </>
   )
}

export default ListLayout;