import React from 'react';
import StepTitle from '../step-title';
import Button from '../../utils/button';
import {StepProps} from "../../../types";

function ReferralStep({ step, onAnswer }: StepProps) {
   return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StepTitle title={step.title} />
            <Button text={step.properties.buttonText} onClick={() => {
               const shareData = {
                  title: "MDN",
                  text: "Learn web development on MDN!",
                  url: window.location.href,
               };

               navigator.share(shareData).then((a: any) => {
                  onAnswer(true);
               });
            }} />
         </div>
      </div>
   )
}

export default ReferralStep;