import React, {CSSProperties} from 'react';

interface TextProps {
   text: string;
   variant?: TextVariant;
   strong?: boolean;
   align?: 'center' | 'start' | 'end';
   customStyle?: CSSProperties
}

type TextVariant = 'error' | 'normal';

function varaintTextColor(varaint: TextVariant) {
   switch (varaint) {
      case 'error': return '#C00000';
      case 'normal': return '#282421';
      default: return '#282421';
   }
}

function Text({ text, strong = false, variant = "normal", align, customStyle }: TextProps) {
   const color = varaintTextColor(variant);

   return <div className="text" style={{ fontWeight: strong ? 500 : 400, color, textAlign: align ? align : 'unset', ...customStyle }}>{text}</div>
}

export default Text;