import React from 'react';

function PoweredBy() {
   return (
      <div className="powered-by" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: '1px solid #BCBCBC', background: '#FFF', padding: 8 }}>
         <img style={{ height: 'auto' }} src={`${new URL('/images/wonka_poweredby.png', 'https://x.wonka.cloud')}`} />
      </div>
   )
}

export default PoweredBy;